<template>
  <div>
    <v-card>
      <v-row>
        <v-col xs="12" class="mb-5">
          <v-card-title>
            <h3 class="primary--text" style="width: 100%"
              >Sign in with Email</h3
            >
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="username" label="Email"></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              :type="show ? 'text' : 'password'"
            ></v-text-field>
            <v-btn
              color="primary"
              @click="logInUnPw({ email: username, password: password })"
              :disabled="!(username && password)"
              >Sign in</v-btn
            >
            <br />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { authComputed } from '@state/helpers'
import appConfig from '@src/app.config'
export default {
  components: {},
  data: () => ({
    username: '',
    password: '',
    show: false,
  }),
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('auth', ['logInUnPw']),
  },
}
</script>
<style scoped></style>

<style lang="css">
Layout {
  text-align: center;
}
</style>
