<template>
  <div>
    <v-container>
      <v-row class="text-center mt-4" align="stretch">
        <v-col cols="12" md="6" class="mb-4">
          <!-- GoogleSignin Component. The code is in components/login/google-signin.vue. To use it, it has to be imported within the script and than define the component name in a components instance. See in the script below -->
          <v-card>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <h3 class="primary--text" style="width: 100%"
                    >Sign in with Google</h3
                  >
                </v-card-title>
                <v-card-text style="overflow-y: auto; height:213px">
                  <div class="d-flex justify-center pt-14">
                    <GoogleSignin alwaysShowButton> </GoogleSignin>
                  </div>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mb-4">
          <!-- UNPWSignIn Component. The code is in components/login/unpw-signin.vue. To use it, it has to be imported within the script and than define the component name in a components instance. See in the script below -->
          <UNPWSignIn></UNPWSignIn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-6">
        <v-col cols="auto">
          <!-- AccountActions Component. The code is in components/login/account-actions.vue. To use it, it has to be imported within the script and than define the component name in a components instance. See in the script below -->
          <AccountActions></AccountActions>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="justify-center pl-0 pt-4" inset app>
      <v-row justify="center">
        <img src="../../assets/identity-logo.png" height="48px" />
        <v-col sm="12" class="text-center"
          >This app uses the Wyoming Identity System for Authentication</v-col
        >
      </v-row>
    </v-footer>
  </div>
</template>
<script>
// IMPORTED COMPONENT the @component is an alias configuration, you can read more about it
// here https://citycode.wyo.gov/#/tutorials/aliases
import GoogleSignin from '@components/login/google-signin'
import UNPWSignIn from '@components/login/unpw-signin'
import AccountActions from '@components/login/account-actions'
import { authComputed } from '@state/helpers'
import appConfig from '@src/app.config'
export default {
  page: {
    title: 'Log in',
    meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },
  // COMPONENT INSTANCE
  components: {
    GoogleSignin,
    UNPWSignIn,
    AccountActions,
  },
  data: () => ({}),
  computed: {
    ...authComputed,
  },
}
</script>
<style scoped></style>
