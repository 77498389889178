<template>
  <v-row>
    <v-col xs="12">
      <span class="px-5">Don't have an account?</span>
      <v-btn color="primary" @click="_register">Register</v-btn>
      <span class="px-5">Forgot your password?</span>
      <v-btn color="primary" @click="dialog2 = true">Reset Password</v-btn>
      <span class="px-5">Resend Email Confirmation?</span>
      <v-btn color="primary" @click="dialog1 = true">Resend</v-btn>
    </v-col>
    <v-dialog v-model="dialog2" max-width="850px">
      <v-card class="pa-5">
        <v-card-text>
          <v-text-field
            label="Enter the email you registered with"
            v-model="email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary"  :disabled="!email" @click="_resetPassword"
            >Send link to reset password</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" max-width="850px">
      <v-card class="pa-5">
        <v-card-text>
          <v-text-field
            label="Enter the email you registered with"
            v-model="email"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn  :disabled="!email" color="primary" @click="_sendConfirmation"
            >Resend Confirmation</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {},
  components: {},
  filters: {},
  data() {
    return {
      dialog1: false,
      dialog2: false,
      email: '',
    }
  },
  created() {},
  computed: {},
  methods: {
    _register() {
      this.$router.push({ path: '/Register', name: 'Register' })
    },
    async _resetPassword() {
      this.dialog2 = false
      try {
        let { data } = await this.$axios.get(
          `/Register/PasswordResetToken?email=${encodeURIComponent(this.email)}`
        )
        this.$store.dispatch('messages/toastMessage', data)
      } catch (err) {
        this.$store.dispatch('errors/handleError', err)
      }
    },
    async _sendConfirmation() {
      this.dialog1 = false
      let user = {
        UserName: this.email,
        Email: this.email,
      }
      try {
        let { data } = await this.$axios.post(
          `/Register/ResendConfirmation/`,
          user
        )
        this.$store.dispatch('messages/toastMessage', data)
      } catch (err) {
        this.$store.dispatch('errors/handleError', err)
      }
    },
  },
  watch: {},
}
</script>
<style scoped></style>
